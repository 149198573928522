import React from 'react';
import './landing-footer.css';
import logo from '../../../assets/logos/amai-conntent.png';

const LandingFooter = () => {
	return (
		<div className='footer-landing'>
			<div className='footer-items'>
				<img src={logo} alt='' className='footer-logo' />
				<p className='mt-5'>
					CTCP DỊCH VỤ VÀ TRUYỀN THÔNG AMAI Số 588 Nguyễn Trãi, Thanh Xuân, Hà Nội.
				</p>
				<p>VP: CT3-2 KĐT Mễ Trì Hạ, Nam Từ Liêm, Hà Nội Mã số thuế: 0109141270</p>
			</div>
			<div className='footer-items'>
				<p className='footer-heading'>Chúng tôi là ai?</p>
				<p>
					<a href='/ve-chung-toi'>Lời nhắn từ CEO</a>
				</p>
				<p>
					<a href='/ve-chung-toi'>Triết lý kinh doanh</a>
				</p>
				<p>
					<a href='/ve-chung-toi'>Thông tin công ty</a>
				</p>
				<p>
					<a href='/ve-chung-toi'>Liên hệ</a>
				</p>
				{/* <p>
					<a href=''>Dịch vụ chăm sóc Fanpage</a>
				</p> */}
			</div>
			<div className='footer-items'>
				<p className='footer-heading'>Chúng tôi làm gì?</p>
				<p>
					<a href='/dich-vu'>Dịch vụ cung cấp</a>
				</p>
				<p>
					<a href='/dich-vu'>Công nghệ kỹ thuật</a>
				</p>
				<p>
					<a href='/dich-vu'>Hình thức hợp tác</a>
				</p>
				<p>
					<a href='/dich-vu'>Dự án của chúng tôi</a>
				</p>
			</div>
			<div className='footer-items'>
				<p className='footer-heading'>Văn hoá - sự kiện</p>
				<p>
					<a href='https://amaicontent.com/blog/'>Tin tức Amai</a>
				</p>
				<p>
					<a href='https://amaicontent.com/blog/'>Tuyển dụng</a>
				</p>
				<p>
					<a href='https://amaicontent.com/blog/'>Blogs công ty</a>
				</p>
			</div>
			<div className='footer-items'>
				<p className='footer-heading'>Chính sách</p>
				<p>
					<a href='/policy'>Chính sách bảo mật</a>
				</p>
				<p>
					<a href='/policy'>Bảo mật thông tin</a>
				</p>
				<p>
					<a href='/policy'>Chính sách cookies</a>
				</p>
				<p>
					<a href='/terms'>Chính sách trang</a>
				</p>
			</div>
		</div>
	);
};

export default LandingFooter;
